/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/

.btn {
	display: block;
	position: relative;
	width: 125px;
	min-width: 125px;
	height: 40px;
	padding: 13px 0;
	cursor: pointer;
	border: none;
	color: $light-colour;
	background-color: $body-colour;
	font-family: $averta-bold;
	text-transform: uppercase;
	letter-spacing: tracking(100);
	@include font-size(15px);
	@include line-height(15px);

	&--input {
		position: relative;
		background-color: $body-colour;

		.btn {
			background-color: rgba(0, 0, 0, 0);
		}
	}

	&--hover {
		overflow: hidden;
		z-index: 1;
		@include transition(all, 0.3s, ease);

		&:after {
			content: "";
			position: absolute;
			left: -100%;
			bottom: 0;
			width: 100%;
			height: 100%;
			background-color: lighten($body-colour, 20%);
			z-index: -2;
			@include transition(all, 0.3s, ease);
		}

		&:hover {
			color: $light-colour;

			&:after {
				left: 0;
			}
		}
	}

	&--large {
		width: 180px;
		height: 80px;
		padding: 30px 0;
		@include font-size(20px);
		@include line-height(20px);

		@include mobilefirst(em($bp-sm)) {
			min-width: 370px;
		}
	}

	&--circle {
		padding: 5px;
		border: 1px solid $light-colour;
		cursor: pointer;
		@include rounded(50%);
		@include transition(all, 0.3s, ease);

		&:hover {
			background-color: $light-colour;

			i {
				color: $body-colour;
			}
		}

		i {
			color: $light-colour;
			width: 22px;
			height: 22px;
			display: block;
			text-align: center;
			position: relative;
			@include font-size(22px);
			@include transition(all, 0.3s, ease);
		}
	}

	&--circle-lg {
		padding: 14px;
		border: 1px solid $body-colour;
		cursor: pointer;
		@include rounded(50%);

		&:hover {
			background-color: $body-colour;

			i {
				color: $light-colour;
			}
		}

		i {
			color: $body-colour;
			width: 44px;
			height: 44px;
			display: block;
			text-align: center;
			position: relative;
			@include font-size(44px);
		}
	}

	&--square {
		cursor: pointer;
		background-color: $light-colour;
		@include transition(all, 0.3s, ease);

		&:hover {

			i:after {
				left: 0;
			}
		}

		i {
			position: relative;
			display: block;
		    width: 42px;
		    height: 42px;
			overflow: hidden;
			@include line-height(42px);
			@include font-size(22px);

			&:before {
				z-index: 1;
			}

			&:after {
				content: "";
				position: absolute;
				left: -100%;
				bottom: 0;
				width: 100%;
				height: 100%;
				background-color: $primary-light;
				z-index: 0;
				@include transition(all, 0.3s, ease);
			}
		}

		&--secondary {
			i {
				&:after {
					background-color: $secondary-light;
				}
			}
		}
	}
}
