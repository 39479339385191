/*------------------------------------*\
    #NAVIGATION
\*------------------------------------*/


.fixed-colour-transition {
	clear: both;
	@include mobilefirst(em($bp-sm)) {
		clear: none;
	}
}

.fixed {

	&__item {
		background-color: $light-colour;
		@include transition(all, 0.5s, ease);
	}
	&__top {
		position: fixed;

		@include mobilefirst(em($bp-sm)) {
			width: 100%;
			height: 21px;
			left: 0;
			top: 0;
			z-index: 9999;
		}
	}
	&__right {
		position: fixed;

		@include mobilefirst(em($bp-sm)) {
			width: 21px;
			height: 100%;
			top: 0;
			right: 0;
			z-index: 9999;
		}
	}
	&__bottom {
		position: fixed;

		@include mobilefirst(em($bp-sm)) {
			width: 100%;
			height: 21px;
			left: 0;
			bottom: 0;
			z-index: 9999;
		}
	}
	&__left {
		position: fixed;

		@include mobilefirst(em($bp-sm)) {
			width: 21px;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 9999;
		}
	}
	&__contact {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9999;
		width: 50%;
		text-align: center;

		@include mobilefirst(em($bp-sm)) {
			top: 20px;
			left: 20px;
			width: auto;
			text-align: inherit;
		}

		a {
			display: block;
			padding: 14px 25px 12px 25px;
			@include line-height(22px);
		}

		&--active {

			a {
				background-color: $light-colour;
			}
		}
	}
	&__menu {
		position: fixed;
		top: 0;
		right: 0;
		z-index: 9999;
		width: 50%;
		text-align: center;

		@include mobilefirst(em($bp-sm)) {
			top: 20px;
			right: 20px;
			width: auto;
			text-align: inherit;
		}
	}
	&__menu-text {
		padding-right: 20px;
		@include line-height(22px);
	}
}

.burger, .burger:before, .burger:after {
	width: 40px;
	height: 2px;
	position: absolute;
	background-color: $body-colour;
	@include transition(all, 0.3s, ease);
}

.burger {
	top: 14px;
	right: 20px;
	margin: 9px 0;

	&:before {
		content: "";
	    top: -9px;
	    left: 0;
	}

	&:after {
		content: "";
	    top: 9px;
	    left: 0;
	}

	&--close {
		top: 12px;
		background-color: transparent;
		@include rotate(45);

		&:before {
			top: 3px;
		}

		&:after {
			top: 3px;
			@include rotate(-90);
		}
	}

	&--wrap {
		padding: 14px 60px 12px 25px;
		position: relative;

		span {
			display: inline-block;
		}
	}
}

.side-navigation {
	width: 100%;
	height: 100%;
	background-color: $light-colour;
	top: 0;
	left: 0;
	z-index: -2;
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	display: flex;
	align-items: center;

	@include mobilefirst(em($bp-sm)) {
		width: calc(100% - 40px);
		height: calc(100% - 40px);
		top: 20px;
		left: 20px;
		@include transition (all, 0.5s, ease);
	}

	.navigation-logo {
		display: none;

		img {
			width: 140px;
			opacity: 0;
			z-index: -2;

			@include mobilefirst(em($bp-xxlg)) {
				width: 180px;
			}

			@include responsive-height-laptop(em(700)) {
				width: 140px;
			}
		}
	}

	&--selected {
		position: fixed;
		opacity: 1;
		visibility: visible;
		z-index: 9998;

		.navigation-logo {
			@include mobilefirst(em($bp-md)) {
				display: block;
			}

			img {
				@include mobilefirst(em($bp-md)) {
					z-index: 0;
					opacity: 1;
				}
			}
		}

		.navigation {
			display: block;
			z-index: 0;

			.navigation-top, .navigation-middle, .navigation-bottom {
				opacity: 1;
			}
		}
	}
}

.side-navigation-trigger {
	cursor: pointer;
}

.stop-scroll {
	height: 100%;
  	overflow: hidden;
}

.navigation {
	width: 100%;
	display: none;
	z-index: -2;
	text-align: center;

	@include mobilefirst(em($bp-md)) {
		text-align: left;
	}

	@include responsive-height-laptop(em(560)) {
		height: 100%;
	    overflow: auto;
		padding-top: 68px;
	}

	.navigation-top, .navigation-middle, .navigation-bottom {
		opacity: 0;
	}

	.navigation-top {
		margin-bottom: 30px;

		@include mobilefirst(em($bp-md)) {
			margin-bottom: 40px;
		}

		@include responsive-height-laptop(em(700)) {
			margin-bottom: 23px;
		}
	}

	.navigation-bottom {
		margin-bottom: 0;
	}

	.navigation-top__item, .navigation-bottom__item {

		@include responsive-height-laptop(em(700)) {
			.delta {
				@include font-size(18px);
			}
		}
	}

	.navigation-middle {
		margin-bottom: 30px;

		@include mobilefirst(em($bp-md)) {
			margin-bottom: 16px;
		}

		&__item {
			cursor: pointer;

			&:last-of-type {
				margin-bottom: 0;
			}

			&:not(:last-of-type) {
				margin-bottom: 12px;
			}

			@include mobilefirst(em($bp-xxlg)) {

				&:not(:last-of-type) {
					margin-bottom: 26px;
				}
			}

			@include responsive-height-laptop(em(700)) {

				&:not(:last-of-type) {
					margin-bottom: 5px;
				}
			}

			a, span {
				@include transition(all, 0.3s, ease);
			}

			&:hover {
				a {
					color: $light-colour;
				}

				span {
					opacity: 1;
				}
			}

			.beta {

				@include mobilefirst(em($bp-md)) {
					@include font-size(35px);
				}
				@include mobilefirst(em($bp-xxlg)) {
					@include font-size(43px);
				}

				@include responsive-height-laptop(em(700)) {
					@include font-size(30px);
				}
			}

			.delta {
				@include responsive-height-laptop(em(700)) {
					@include font-size(18px);
				}
			}

			span {
				display: none;
				height: 22px;

				@include mobilefirst(em($bp-md)) {
					display: block;
					opacity: 0;
					color: $body-colour;
				}
			}
		}
	}
}

.navigation-top, .navigation-bottom {

	&__item {
		display: inline-block;
		margin-bottom: 0;

		&:not(:last-of-type) {
			margin-right: 20px;
		}
	}
}

.footer .navigation-bottom {
	margin-bottom: 0;
}

.pagination {
	width: 100%;

	&--space {
		@include mobilefirst(em($bp-sm)) {
			padding: 0 40px;
		}

		@include mobilefirst(em($bp-md)) {
			padding: 0 0 60px;
		}

		@include mobilefirst(em($bp-xlg)) {
			padding: 0 0 90px;
		}
	}

	&__item {
		width: 100%;
		float: left;
		padding: 0;
		text-align: center;

		&:not(:last-of-type) {
			margin-bottom: 20px;
		}

		@include mobilefirst(em($bp-md)) {
			width: 33.33333%;
			padding: 0 20px;

			&:nth-child(1) {
				text-align: left;
			}

			&:nth-child(3) {
				text-align: right;
			}

			&:not(:last-of-type) {
				margin-bottom: 0px;
			}
		}

		ul {
			margin-bottom: 0;

			li {
				display: inline-block;
				padding: 0 5px;
			}

			.active a {
				color: $primary-colour;
			}
		}
	}
}
