/*------------------------------------*\
    #FONTFACE
\*------------------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap');

@font-face {
  font-family: "daw-green";
  src:url("../../fonts/daw-green/daw-green.eot");
  src:url("../../fonts/daw-green/daw-green.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/daw-green/daw-green.woff") format("woff"),
    url("../../fonts/daw-green/daw-green.ttf") format("truetype"),
    url("../../fonts/daw-green/daw-green.svg#daw-green") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "daw-green" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "daw-green" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-angle-down:before {
  content: "\61";
}
.icon-angle-left:before {
  content: "\62";
}
.icon-angle-right:before {
  content: "\63";
}
.icon-angle-up:before {
  content: "\64";
}
.icon-android-add:before {
  content: "\65";
}
.icon-map-marker:before {
  content: "\66";
}
