/*------------------------------------*\
    #LAYOUT
\*------------------------------------*/

.section {
	padding: 60px 20px;

	@include mobilefirst(em($bp-sm)) {
		padding: 60px;
	}

	@include mobilefirst(em($bp-xlg)) {
		padding: 90px;
	}

	&--lg {
		padding: 75px 20px;

		@include mobilefirst(em($bp-sm)) {
			padding: 75px 45px;
		}

		@include mobilefirst(em($bp-xlg)) {
			padding: 120px 90px;
		}
	}

	&--xlg {
		padding: 90px 20px;

		@include mobilefirst(em($bp-sm)) {
			padding: 90px 45px;
		}

		@include mobilefirst(em($bp-xlg)) {
			padding: 180px 90px;
		}
	}

	&--no-top {
		padding: 0 20px 60px;

		@include mobilefirst(em($bp-sm)) {
			padding: 0 60px 60px;
		}

		@include mobilefirst(em($bp-xlg)) {
			padding: 0 90px 90px;
		}
	}

	&--no-bottom {
		padding: 60px 20px 0;

		@include mobilefirst(em($bp-sm)) {
			padding: 60px 60px 0;
		}

		@include mobilefirst(em($bp-xlg)) {
			padding: 90px 90px 0;
		}
	}

	&--top {
		padding: 60px 20px 0;

		@include mobilefirst(em($bp-xlg)) {
			padding: 90px 0 0;
		}
	}

	&--bottom {
		padding: 0 20px 60px;

		@include mobilefirst(em($bp-xlg)) {
			padding: 0 0 90px;
		}
	}

	&--thin {
		padding: 60px 20px;

		@include mobilefirst(em($bp-xlg)) {
			padding: 90px 0;
		}
	}

	&--thin-lg {
		padding: 90px 20px;

		@include mobilefirst(em($bp-xlg)) {
			padding: 180px 0;
		}
	}

	&--triangle-left, &--triangle-right, &--triangle-center {
		position: relative;

		&:after {
			content: "";
			background-image: url(/assets/img/icons/triangle-green.svg);
			background-repeat: no-repeat;
		    position: absolute;
		    top: 0;
		    height: 100%;
		    pointer-events: none;
			z-index: -2;
		}
	}

	&--triangle-left {

		&:after {
			width: 70%;
			background-position: left top;
			background-size: 100%;
			left: 0;
			@include transform(scale(-1, 1));
		}
	}

	&--triangle-right {

		&:after {
			width: 70%;
			background-position: right top;
			background-size: 100%;
			right: 0;
		}
	}

	&--triangle-center {
		position: relative;

		&:after {
			width: calc(100% - 120px);
			background-position: center center;
			background-size: 40%;
			top: -30px;
			left: 120px;
			transform: rotate(180deg);
		}
	}
}

.banner {
	/*max-height: calc(100vh - 40px);*/
	z-index: 1;
	overflow: hidden;
	position: relative;

	&__item {
		position: relative;
		width: 100%;

		img {
		    display: block;
		    width: 100%;
		    height: 100%;
		}

		.no-mob {
			display: none;

			@include mobilefirst(em($bp-lg)) {
				display: block;
			}
		}

		.mob-only {
			display: block;

			@include mobilefirst(em($bp-lg)) {
				display: none;
			}
		}
	}

	&__title {
		width: 100%;
		position: absolute;
		top: 60%;
	    left: 50%;
		padding: 0 20px;
	    -webkit-transform: translate(-50%, -50%);
	        -ms-transform: translate(-50%, -50%);
	            transform: translate(-50%, -50%);

		@include mobilefirst(em($bp-sm)) {
			top: 55%;
		}

		@include mobilefirst(em($bp-md)) {
			top: 40%;
		}

		@include mobilefirst(em($bp-lg)) {
			top: 50%;
		}

		@include mobilefirst(em($bp-xlg)) {
			top: 45%;
		}

		h2, h3 {
			max-width: 640px;
			margin: 0 auto;
			text-align: center;
		}

		h3 {
			margin-top: 25px;
		}

		.alpha {
			@include responsive(em($bp-sm)) {
				@include font-size(32px);
			}
		}

		&--center {
			@include mobilefirst(em($bp-sm)) {
				top: 55%;
			}

			@include mobilefirst(em($bp-md)) {
				top: 40%;
			}

			@include mobilefirst(em($bp-lg)) {
				top: 60%;
			}

			@include mobilefirst(em($bp-xlg)) {
				top: 50%;
			}
		}
	}

	&--overlay {
		position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    background: $body-colour;
	    opacity: 0.5;
	}

	&--triangle-left, &--triangle-right {
		background-image: url(/assets/img/icons/triangle.svg);
		background-repeat: no-repeat;
		background-size: 100%;
	    position: absolute;
	    bottom: 0;
	    height: 100%;
	    width: 30%;
	    pointer-events: none;
		z-index: 2
	}

	&--triangle-left {
		background-position: left bottom;
		left: 0;
		opacity: 1;
		@include transition(all, 1.0s, ease);
	}

	&--triangle-right {
		background-position: right bottom;
		right: 0;
		@include transform(scale(-1, 1));
	}

	&--triangle-transition {
		opacity: 0;
		left: -100px;
	}

	&--logo {
		width: 110px;
		padding: 70px 0 50px;
		margin: auto;
		text-align: center;
	}

	&--small {

		.banner__title {
			top: 60%;

			@include mobilefirst(em($bp-sm)) {
				top: 55%;
			}

			@include mobilefirst(em($bp-md)) {
				top: 40%;
			}

			@include mobilefirst(em($bp-lg)) {
				top: 65%;
			}

			@include mobilefirst(em($bp-xxlg)) {
				top: 55%;
			}

			h3 {
				margin-top: 0;
				margin-bottom: 25px;

				@include mobilefirst(em($bp-lg)) {
					margin-top: 0;
					margin-bottom: 10px;
				}

				@include mobilefirst(em($bp-xxlg)) {
					margin-top: 0;
					margin-bottom: 25px;
				}
			}
		}
	}
}

.logo {
	width: 110px;
	z-index: 9000;
	top: 10%;
	@include horizontal-align;
}

.scroll--up.scroll--active {
	opacity: 1;
	-webkit-transform: translateX(0);
		-ms-transform: translateX(0);
			transform: translateX(0);
}

.scroll {

	&--up {
		display: none;
		clear: both;
		@include transition(all, 0.3s, ease);

		@include mobilefirst(em($bp-sm)) {
			width: 74px;
			display: block;
			position: fixed;
			bottom: 15%;
			right: 5%;
			z-index: 9000;
			clear: none;
			opacity: 0;
			-webkit-transform: translateX(100%);
		        -ms-transform: translateX(100%);
		            transform: translateX(100%);
		}

		span {

			i {
				top: 1px;
			}
		}
	}

	&--down {
		display: none;

		@include mobilefirst(em($bp-sm)) {
			display: block;
			bottom: 12%;
			z-index: 9000;
		}

		@include mobilefirst(em($bp-lg)) {
			display: none;
		}

		@include mobilefirst(em($bp-xxlg)) {
			display: block;
		}

		@include mobilefirst(em(1800)) {
			bottom: 125px;
		}

		span {
			display: block;

			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 28px;
				left: 11px;
				width: 1px;
				height: 125px;
				background-color: $light-colour;
			}

			i {
				top: 2px;
			}
		}
	}
}

.scroll-down-inner {
	display: block;
	width: 74px;
	margin: auto;

	span {

		i {
			top: 4px;
		}
	}
}

.vertical-line {
	display: none;

	@include mobilefirst(em($bp-md)) {
		display: block;
		position: relative;
		z-index: 2;
		padding-bottom: 155px;
	}

	@include mobilefirst(em($bp-lg)) {
		padding-bottom: 50px;
	}

	@include mobilefirst(em($bp-xxlg)) {
		padding-bottom: 155px;
	}

	&:before {
		content: "";
		width: 4px;
		height: 310px;
		margin: -155px auto 0;
		background-color: $light-colour;
		@include horizontal-align;

		@include mobilefirst(em($bp-lg)) {
			height: 100px;
			margin: -50px auto 0;
		}

		@include mobilefirst(em($bp-xxlg)) {
			height: 310px;
			margin: -155px auto 0;
		}

	}
}

.tagline {
	max-width: 100%;
	text-align: center;
	bottom: 10%;
	z-index: 9000;

	@include mobilefirst(em($bp-sm)) {
		bottom: 20%;
	}

	@include mobilefirst(em($bp-lg)) {
		bottom: 10%;
	}

	@include mobilefirst(em($bp-xlg)) {
		bottom: 20%;
	}
}

.article {
	max-width: 500px;
	margin: auto;

	h2, h3 {
		margin-bottom: 30px;
	}

	p {
		margin-bottom: 25px;
	}

	&--sm {
		max-width: 340px;

		p {
			margin-bottom: 45px;
		}
	}

	&--md {
		max-width: 420px;

		h2, h3 {
			max-width: 340px;
			margin: 0 auto 30px;
		}

		p {
			max-width: 340px;
			margin: 0 auto 45px;
		}
	}

	&--lg {
		max-width: 620px;

		p {
			margin-bottom: 75px;
		}
	}

	&--full {
		max-width: inherit;
	}

	&--left {

		@include mobilefirst(em($bp-lg)) {
			float: left;
		}
	}

	&--right {

		@include mobilefirst(em($bp-lg)) {
			float: right;
		}
	}

	&--left-minus {

		@include mobilefirst(em($bp-lg)) {
			margin-left: -90px;
		}
	}

	&--bottom {
		margin-bottom: 20px;

		@include mobilefirst(em($bp-md)) {
			margin-bottom: 0;
		}
	}

	&__content {

		h1, h2, h3, h4, h5, h6, blockquote {
			font-family: $pfd-reg;
		    font-weight: normal;
		    font-style: normal;
			color: $body-colour;
		}

		h1, h2 {
			text-transform: uppercase;
			@include font-size($sm-x-large);

			@include mobilefirst(em($bp-md)) {
				@include font-size($x-large);
			}
		}

		h3 {
			margin-bottom: 0;
			text-transform: uppercase;
			@include font-size($sm-large);

			@include mobilefirst(em($bp-md)) {
				@include font-size($large);
			}
		}

		h4 {
			margin-bottom: 10px;
			@include font-size($sm-large);

			@include mobilefirst(em($bp-md)) {
				@include font-size($large);
			}
		}

		h5 {
			margin-bottom: 10px;
			@include font-size($sm-medium);

			@include mobilefirst(em($bp-md)) {
				@include font-size($medium);
			}
		}

		h6 {
			margin-bottom: 10px;
			@include font-size($sm-small);

			@include mobilefirst(em($bp-md)) {
				@include font-size($small);
			}
		}

		p, li {
			font-family: $averta-reg;
			font-weight: normal;
		    font-style: normal;
			color: $tertiary-colour;
			@include font-size($x-small);
			@include line-height(20px);

			@include mobilefirst(em($bp-sm)) {
				@include font-size($base-font-size);
				@include line-height(23px);
			}
		}

		li {
			margin-bottom: 0px;
		}

		ul {
			list-style-type: disc;
			margin-left: 20px;
			margin-bottom: 30px;
		}

		ol {
			list-style-type: decimal;
			margin-left: 20px;
			margin-bottom: 30px;
		}

		a {
			font-family: $averta-bold;
		    font-weight: normal;
		    font-style: normal;
			letter-spacing: tracking(100);
			@include font-size($x-small);
			@include transition(all, 0.3s, ease);
		}

		blockquote {
			position: relative;
			quotes: "\201C""\201D""\2018""\2019";

			&:before {
				position: absolute;
				top: 0;
				left: 0;
				font-family: $averta-bold;
				content: open-quote;
				color: $foreground-colour;
				@include font-size(140px);
			}

			p {
				font-family: $pfd-reg;
			    font-weight: normal;
			    font-style: normal;
				padding-top: 70px;
				padding-bottom: 30px;
				color: $body-colour;
				@include font-size(24px);
				@include line-height(32px);

				@include mobilefirst(em($bp-sm)) {
					padding-top: 0;
					margin-left: 100px;
					@include font-size($sm-large);
					@include line-height(38px);
				}

				@include mobilefirst(em($bp-md)) {
					@include font-size($large);
					@include line-height(49px);
				}
			}
		}

		&--btn {
			a {
				position: relative;
				display: inline-block;
				height: 33px;
				overflow: hidden;
				text-transform: uppercase;

				&:before, &:after {
					content: "";
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 4px;
					background-color: $primary-colour;
					@include transition(all, 0.3s, ease);
				}

				&:after {
					left: -100%;
					background-color: $primary-light;
				}

				&:hover {

					&:after {
						left: 0;
					}
				}
			}
		}

		&--blog {
			p, a, li {
				@include font-size(16px);
				@include line-height(24px);

				@include mobilefirst(em($bp-sm)) {
					@include font-size(20px);
					@include line-height(35px);
				}
			}
			a {
				letter-spacing: tracking(50);
			}
			li {
				margin-bottom: 4px;
			}
		}
	}

	&__title {
		position: relative;
		width: 100%;
	    height: 118px;
	    margin: 0 0 60px;

		@include mobilefirst(em($bp-md)) {
			max-width: 330px;
			height: 142px;
			margin: 0 0 60px;
		}

		h4 {
			position: absolute;
			top: 0;
			left: 0;
			z-index: -2;
			color: darken($foreground-colour, 5%); //dae1e4
			@include font-size(160px);
			@include line-height(140px);

			@include mobilefirst(em($bp-md)) {
				@include font-size(200px);
				@include line-height(167px);
			}
		}

		h3 {
			display: block;
		    position: absolute;
		    top: 0;
		    right: 0;
		    margin-top: 10px;
		    margin-bottom: 15px;
		}

		h2 {
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			margin-top: 55px;
			margin-bottom: 15px;
			padding-top: 15px;
			width: 100%;
			border-top: 2px solid $body-colour;
		}
	}

	&__img {

		img {
			display: block;
			width: 100%;
		}
	}

	&__icon {

		img {
			width: 50px;
			margin: 0 auto 30px;

			@include mobilefirst(em(340)) {
				width: 70px;
			}

			@include mobilefirst(em($bp-xxlg)) {
				width: inherit;
			}
		}

		&--right {
			position: absolute;
			height: 70px;
			top: 60px;
			right: 0;

			@include mobilefirst(em($bp-md)) {
				top: 75px;
			}

			@include mobilefirst(em($bp-xxlg)) {
				height: 100px;
				top: 45px;
			}
		}

		&--inline {
			display: inline-block;
			position: relative;
			margin-bottom: 45px;

			&:not(:last-of-type) {
				padding-right: 8px;
				margin-right: 8px;
			}

			@include mobilefirst(em(340)) {
				&:not(:last-of-type) {
					padding-right: 14px;
					margin-right: 14px;

					@include mobilefirst(em($bp-xxlg)) {
						padding-right: 20px;
						margin-right: 20px;
					}

					&:after {
						content: "";
						position: absolute;
						top: 0;
						right: 0;
						width: 1px;
						height: 100%;
						background: lighten($tertiary-colour, 40%);
					}
				}
			}

			img, span {
				display: block;
			}

			img {
				margin: 0 auto 20px;
			}

			span {
				@include responsive(em(320)) {
					@include font-size(13px);
				}
			}
		}
	}
}

.discover-text {

	@include mobilefirst(em($bp-xxlg)) {
		position: relative;

		&:before {
			content: "Discover";
			position: absolute;
			top: -50px;
			right: 5%;
			-webkit-transform: translateY(-60%);
		    -ms-transform: translateY(-60%);
		    transform: translateY(-60%);
			color: $foreground-colour;
			z-index: -2;
			font-family: $pfd-thin;
			letter-spacing: tracking(20);
			@include font-size(370px);
		}
	}
}

.explore-text {

	@include mobilefirst(em($bp-xxlg)) {
		position: relative;
		margin-top: 340px;

		&:before {
			content: "Explore";
			position: absolute;
			top: 0;
			left: 5%;
			-webkit-transform: translateY(-50%);
		    -ms-transform: translateY(-50%);
		    transform: translateY(-50%);
			padding-bottom: 110px;
			color: $foreground-colour;
			z-index: -2;
			font-family: $pfd-thin;
			letter-spacing: tracking(20);
			@include font-size(370px);
		}
	}
}

.team {

	&__item {
		margin-bottom: 30px;
		box-sizing: border-box;
	  	@include flexbox();
		@include align-items(center);

		@include mobilefirst(em($bp-md)) {
			margin-bottom: 0;
		}
	}

	&__img {
		width: 50%;

		@include mobilefirst(em($bp-xlg)) {
			width: 60%
		}

		img {
			display: block;
			width: 100%;
		}
	}

	&__title {
		width: 50%;
		padding: 25px 15px;
		margin-left: -30px;
		background-color: $light-colour;

		@include mobilefirst(em($bp-xlg)) {
			width: 40%
		}

		h3 {
			margin-bottom: 5px;
		}
	}
}

.team-col:nth-child(2n) .team__item {

	@include mobilefirst(em($bp-md)) {
		margin-top: 90px;
	}
}

.portfolio {

	&__header {

		@include mobilefirst(em($bp-lg)) {
			position: relative;
			z-index: 1;
			margin-bottom: -159px;
		}

		@include mobilefirst(em($bp-xxlg)) {
			margin-bottom: -179px;
		}
	}

	&__item {
		width: 100%;
		height: 375px;
		display: block;
		position: relative;
		overflow: hidden;
		border: 10px solid $light-colour;

		&:hover {

			@include mobilefirst(em($bp-lg)) {
				.portfolio__img {
					opacity: 1;
					transform: scale(1.04);
				}

				.portfolio__content {
					opacity: 0;
				}
			}
		}

		&--first {

			@include mobilefirst(em($bp-lg)) {
				height: 750px;
			}
		}
	}

	&__img {
		width: 100%;
		height: 100%;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 0.3;
		-webkit-backface-visibility: hidden;
		-webkit-transform: translateZ(0) scale(1.0, 1.0);
		@include transition(all, 0.3s, ease);
	}

	&__img-top-right {
		margin: 20px 0;

		@include mobilefirst(em($bp-md)) {
			width: 40%;
			position: absolute;
			top: 25%;
			right: 0;
			margin: 0;
		}
	}

	&__content {
		width: 100%;
		position: absolute;
		left: 0;
		bottom: 0;
		padding: 35px 35px 30px;
		@include transition(all, 0.3s, ease);

		h2 {
			margin: 10px 0 0 25px;
		}
	}
}

.img-border-top-bot {
	border-top: 10px solid $light-colour;
	border-bottom: 10px solid $light-colour;
}

.img-border-left-right {
	border-left: 10px solid $light-colour;
	border-right: 10px solid $light-colour;
}

.blog-list {

	&__item {
		position: relative;
		display: block;
		margin: 0 auto 40px;
		border-left: 1px solid $light-colour;
		border-right: 1px solid $light-colour;
		border-bottom: 1px solid $light-colour;

		@include mobilefirst(em($bp-md)) {
			max-width: 295px;
		}

		@include mobilefirst(em($bp-xlg)) {
			height: 510px;
			margin: 0 auto 45px;
		}

		@include mobilefirst(em($bp-xxlg)) {
			height: 640px;
		}

		&:hover {

			.blog-list__img img {
				@include transform(scale(1.04));
			}

			.blog-list__title {
				background-color: darken($foreground-colour, 5%);
			}
		}
	}

	&__img {
		overflow: hidden;
		border: 1px solid $light-colour;

		img {
			width: 100%;
			display: block;
			-webkit-backface-visibility: hidden;
			@include transform(translateZ(0) scale(1.0, 1.0));
			@include transition(all, 0.3s, ease);
		}
	}

	&__title {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		min-height: 160px;
		padding: 30px 18px;
		background-color: $foreground-colour;
		@include transition(all, 0.3s, ease);

		@include mobilefirst(em($bp-xlg)) {
			padding: 50px 30px;
			height: 290px;
		}
	}

	.blog-col {

		&:nth-child(4n+1) .blog-list__title {
			@include mobilefirst(em(1410)) {
				height: 360px;
			}
		}

		&:nth-child(4n+4) .blog-list__title {
			@include mobilefirst(em(1410)) {
				height: 220px;
			}
		}
	}
}

.contact {

	&__item {

		&:not(:last-of-type) {
			margin-bottom: 60px;
		}

		h2 {
			margin-bottom: 30px;
		}
	}

	&__address {
		max-width: 220px;
		margin: 0 auto 130px;
	}

	&__logos {

		img {
			display: inline-block;
			width: 80px;

			&:not(:last-of-type) {
				margin-right: 10px;
			}

			@include mobilefirst(em($bp-md)) {
				width: auto;

				&:not(:last-of-type) {
					margin-right: 30px;
				}
			}
		}
	}
}

.map {
	.cmsmap {
		width: 100%;
		height: 100%;
		min-width: 200px;
		min-height: 420px;

		@include mobilefirst(em($bp-md)) {
			min-height: 780px;
		}

		img {
			max-width: auto;
		}
	}
}

.footer {
	min-height: calc(100vh - 40px);
	padding: 90px 20px;
	display: flex;
	align-items: center;

	&--container {
		width: 100%;
	}

	@include mobilefirst(em($bp-sm)) {
		padding: 90px 0;
	}

	@include mobilefirst(em($bp-xlg)) {
		padding: 180px 0 120px;
	}

	&__item {

		&:not(:last-of-type) {
			margin-bottom: 30px;
		}

		&:first-of-type {
			margin-bottom: 60px;
		}

		h2 {
			@include line-height(23px);
		}
	}

	&__logo {
		width: 110px;
		margin: auto;
	}

	&__address {
		max-width: 220px;
		margin: 0 auto;
	}

	&__copy {
		margin-top: 80px;

		p {
			display: block;
			position: relative;

			@include mobilefirst(em($bp-md)) {
				display: inline-block;
				line-height: 30px;

				&:not(:last-of-type) {
					&:after {
						content: ".";
						padding: 0 10px;
					}
				}
			}
		}
	}
}
