/*------------------------------------*\
    #LISTS
\*------------------------------------*/

.list {

	li {
		font-family: $averta-reg;
		font-weight: normal;
		font-style: normal;
		color: $tertiary-colour;
		@include font-size($x-small);
		@include line-height(20px);

		@include mobilefirst(em($bp-sm)) {
			@include font-size($base-font-size);
			@include line-height(23px);
		}

		:not(:last-of-type) {
			margin-bottom: 20px;
		}
	}
}
