/*------------------------------------*\
    #VARIABLES
\*------------------------------------*/





/*------------------------------------*\
    #BREAKPOINTS
\*------------------------------------*/

/**
* 1. bp-small is mainly for devices below 480px (mainly iPhones etc)
* 2. bp-med is for tablet devices i.e. iPad / iPad Mini
* 3. bp-large is for laptops
*/

$bp-sm: 480;
$bp-md: 780;
$bp-lg: 900;
$bp-xlg: 1060;
$bp-xxlg: 1280;




/*------------------------------------*\
    #COLOUR-VARIABLES
\*------------------------------------*/

/**
  * One of the best reasons to use Sass is variables.
  * They help keep your code DRY, which makes it easy to maintain and change.
  * However, with colors, it’s easy for your variables to get out of hand.
  *
  * Solution - Use Name that colour - http://bit.ly/1KCISUE
  * Name your variables after colors like you were trying to do, but without the naming difficulty
  * Suggestion by David Walsh - http://bit.ly/1I62ZLL
*/




/**
  * Colours used for the Product Selector. Taken from Chicopee Americas website
*/



/**
  * Other Random Colour Options
*/




/**
* 1. Primary colour is the green colour
* 2. Secondary colour is the blue colour
* 3. Tertiary colour is the Grey colour
* 4. Light colour is the main colour for backgrounds
* 5. Foreground colour is the designs alternate colour for backgrounds
* 6. Body colour is the colour of the main body text
*/


$primary-colour: #b9bf33;
$primary-light: #dcdf99;
$secondary-colour: #025687;
$secondary-light: #9abbcf;
$tertiary-colour: #656565;
$grey-colour: #c8ccc7;
$light-colour: #ffffff;
$foreground-colour: #edf2f4;
$body-colour: #000;


/*------------------------------------*\
    #FONT-SIZES
\*------------------------------------*/

/**
  * 1. Default font size is 16px.
  * 2. The rest are various font sizes used throughout the site
*/

$base-font-size: 16px;

$xx-large: 60px;
$x-large: 46px;
$large: 32px;
$medium: 20px;
$small: 18px;
$x-small: 15px;

$xx-small: 14px;

$sm-xx-large: 42px;
$sm-x-large: 35px;
$sm-large: 28px;
$sm-medium: 20px;
$sm-small: 17px;

/*------------------------------------*\
    #FONT-FAMILIES
\*------------------------------------*/

$averta-reg: 'Poppins', Helvetica, Arial, sans-serif;
$averta-bold: 'Poppins', Helvetica, Arial, sans-serif;
$pfd-reg: 'Roboto', Helvetica, Arial, sans-serif;
$pfd-light: 'Roboto', Helvetica, Arial, sans-serif;
$pfd-thin: 'Roboto', Helvetica, Arial, sans-serif;


/*------------------------------------*\
    #EASING
\*------------------------------------*/

$transform-bg: transform background;
$transform-opactiy: transform, opacity;
$maxheightpadding: max-height padding;
$widthcolor: width color;
$easeInOutCubic:  cubic-bezier(0.645, 0.045, 0.355, 1);
$easeInOutSine: cubic-bezier(0.445, 0.050, 0.550, 0.950);
$easeOutQuart: cubic-bezier(0.165, 0.840, 0.440, 1);
$easebtnbdr: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$lazyltr: cubic-bezier(0.250, 0.460, 0.450, 0.940);
