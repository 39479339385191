/*------------------------------------*\
    #FORMS
\*------------------------------------*/

.contact-form {

	form {
		clear: both;
		overflow: auto;
		padding: 0 10px;

		@include mobilefirst(em($bp-sm)) {
			padding: 0;
		}
	}

	label, input, textarea {
		display: block;
		width: 100%;
	}

	input[type="text"], textarea {
		font-family: $averta-reg;
		font-size: $x-small;
		padding: 10px 0;
		border: none;
		border-bottom: 1px solid $body-colour;
		// background-color: $grey-colour;
	}

	textarea {
		min-height: 140px;
	}

	&__item {
		width: 100%;
		float: left;
		margin-bottom: 75px;

		@include mobilefirst(em($bp-sm)) {
			width: calc(50% - 10px);
		}

		&:nth-child(2n+1) {
			@include mobilefirst(em($bp-sm)) {
				margin-right: 20px;
			}
		}
	}

	&__message {
		width: 100%;
		clear: both;
	}

	&__submit {
		float: right;
		margin-top: 25px;
	}

	h2 {
		margin-bottom: 100px;
	}
}
