/*------------------------------------*\
    #LINKS
\*------------------------------------*/





a {
    text-decoration: none;
    outline: none;
	cursor: pointer;
}

.link {
	position: relative;
	display: inline-block;
	height: 28px;
	overflow: hidden;
	@include transition(all, 0.3s, ease);

	&:before, &:after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 4px;
		background-color: $primary-colour;
		@include transition(all, 0.3s, ease);
	}

	&:after {
		left: -100%;
		background-color: $primary-light;
	}

	&:hover {

		&:after {
			left: 0;
		}
	}

	&--plus {
		padding-right: 21px;

		&:hover {

			i {
				right: -2px;
			}
		}

		i {
			position: absolute;
			top: 0;
			right: 0;
			@include transition(all, 0.3s, ease);
		}
	}

	&--portfolio {
		width: 100%;
		max-width: 260px;

		&:before, &:after {
			height: 3px;
			background-color: $body-colour;
		}

		&:hover {

			&:after {
				left: -100%;
			}
		}

		i {
			position: absolute;
			top: 0;
			left: 120px;
			@include transition(all, 0.3s, ease);
		}
	}

	&--marker {
		display: inline-block;
		padding-left: 26px;
		position: relative;

		i {
			position: absolute;
			top: -2px;
			left: 0px;
			@include font-size(20px);
			@include transition(all, 0.3s, ease);
		}

		&:hover {

			i {
				left: -2px;
			}
		}
	}

	&--secondary {

		&:before {
			background-color: $secondary-colour;
		}

		&:after {
			background-color: $secondary-light;
		}
	}

	&--light {

		&:before {
			background-color: $light-colour;
		}

		&:after {
			background-color: $body-colour;
		}
	}

	&--fishtank {
		display: inline-block;

		span {
			display: inline-block;
			position: relative;
			height: 30px;
			top: 9px;
			left: 5px;
		}

		svg path {
			@include transition(all, 0.3s, ease);
		}

		&:hover svg path {
			fill: $light-colour;
		}
	}
}

.nav-primary {

	&--active {
		background-color: $primary-light !important;
	}
}

.nav-secondary {

	&--active {
		background: $secondary-light !important;
	}
}

.nav-grey {

	&--active {
        background: $grey-colour !important;
    }
}
