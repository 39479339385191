/*------------------------------------*\
    #SLIDERS
\*------------------------------------*/

.home-slider {

	&__arrows {
		display: none;
		padding: 1px;

		@include mobilefirst(em($bp-md)) {
			display: block;
			z-index: 2;
			bottom: 30%;
			@include horizontal-align;
		}

		@include mobilefirst(em($bp-lg)) {
			bottom: 22%;
		}

		@include mobilefirst(em($bp-xlg)) {
			bottom: 30%;
		}
	}

	&__prev {
		margin-right: 25px;

		i {
			right: 1px;
		}
	}

	&__next {
		margin-left: 25px;

		i {
			left: 1px;
		}
	}
}

.home-slider__prev, .home-slider__next {
	display: inline-block;
	padding: 5px;

	i {
		top: 1px;
	}
}

.number-output {
	display: inline-block;
	vertical-align: top;
	@include line-height(34px);

	span {
		display: inline-block;

		&:nth-child(2) {
			margin: 0 2px;
		}
	}
}

.slider-wrapper {
	box-sizing: border-box;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	-moz-align-items: center;
	align-items: center;
}
.general-slider-text {
	width: 30%;
}
.general-slider-image {
	width: 75%;
	margin-left: -5%;
}

.slider {

	&__item {
		@include mobilefirst(em($bp-xxlg)) {
			padding: 0 70px;
		}
	}
	&__content {
		width: 100%;
		float: left;
		padding: 0 0 45px 0;
		text-align: center;
		position: relative;
		z-index: 2;
		opacity: 0;
		transform: translateX(-100%);
		@include transition(all, 1.2s, ease);

		@include mobilefirst(em($bp-sm)) {
			padding: 0 25px 45px 25px;
		}

		@include mobilefirst(em($bp-lg)) {
			width: 40%;
			padding: 90px 50px;
		}

		@include mobilefirst(em($bp-xxlg)) {
			width: 30%;
			background-color: $light-colour;
		}
	}
	&__img {
		display: block;
		width: 100%;
		float: left;
		transform: translateX(-100%);
		@include transition(all, 1.2s, ease);

		@include mobilefirst(em($bp-lg)) {
			width: 60%;
			transform: translateX(100%);
		}

		@include mobilefirst(em($bp-xxlg)) {
			width: 75%; 
		}

		img {
			width: 100%;
		}
	}
	&__arrows {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;

		@include mobilefirst-between(900px, 1800px) {
			bottom: inherit;
			left: inherit;
			width: initial;
			right: -82px;
			top: 50%;
			-webkit-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
					transform: translateY(-50%);
		}

		@include mobilefirst(em(1800)) {
			top: 0;
			right: -82px;
			bottom: inherit;
			left: inherit;
			width: initial;
		}
	}
}

.general-slider .slider__item.slick-active .slider__content {
	opacity: 1;
	transform: translateX(0);
}

.general-slider .slider__item.slick-active .slider__img {
	transform: translateX(0);
}

.slider__prev, .slider__next {
	display: inline-block;
	position: absolute;
	top: -21px;

	@include mobilefirst(em($bp-lg)) {
		display: block;
		margin: 0 20px 20px;
		position: initial;
	}

	i:before {
		position: relative;
		top: 3px;
	}
}
.slider__prev {
	left: 20px;
}
.slider__next {
	right: 20px;
}

.slider.slick-initialized .slider__item.slick-slide {

	@include mobilefirst(em($bp-lg)) {
		box-sizing: border-box;
	  	@include flexbox();
		@include align-items(center);
	}
}


.portfolio-slider {

	&--triangle-center {

		&:after {
			width: 100%;
			background-position: 40% 62%;
			background-size: 38%;
			left: 0;
		}
	}

	&__item {
		position: relative;

		@include mobilefirst(em($bp-xxlg)) {
			padding-top: 350px;
		}
	}

	&__title {
		min-height: 150px;
		text-align: center;

		h2 {
			margin-bottom: 30px;
		}
	}

	&__img {
		opacity: 0;
		transform: translateX(-100%);
		@include transition(all, 1.2s, ease);

		@include mobilefirst(em($bp-xlg)) {
			margin-top: 0;
		}

		@include mobilefirst(em($bp-xxlg)) {
			transform: translateY(100%);
		}
	}

	&__content {
		margin-bottom: 60px;
		opacity: 0;
		transform: translateX(-100%);
		z-index: 2;
		margin-bottom: 60px;
		@include transition(all, 1.2s, ease);

		@include mobilefirst(em($bp-md)) {
			transform: translateX(100%);
		}

		@include mobilefirst(em($bp-xxlg)) {
			margin-bottom: 0;
			transform: translateX(-100%);
		}
	}

	.portfolio__img-top-right {
		position: relative;
		width: 100%;
		top: 0;
		z-index: -2;
		margin: 0;
		opacity: 0;
		transform: translateX(-100%);
		@include transition(all, 1.2s, ease);

		@include mobilefirst(em($bp-md)) {
			transform: translateX(100%);
		}

		@include mobilefirst(em($bp-lg)) {
			z-index: inherit;
		}

		@include mobilefirst(em($bp-xxlg)) {
			width: 40%;
		    position: absolute;
		    right: 0;
		    margin: 0;
		}
	}

	.number-output {
		display: none;

		@include mobilefirst(em($bp-xxlg)) {
			display: block;
			width: 100%;
			height: 100%;
			min-height: 74px;
			position: relative;
			margin-top: 0;
		}

		&--portfolio {
			position: absolute;
			bottom: 0;
			right: 0;
			width: 74px;
			height: 74px;
			line-height: initial;
			margin: 0 3% 3%;

			&:before {
				content: "";
				position: absolute;
				right: 100px;
				top: 33px;
				width: 100px;
				height: 1px;
				background-color: #000;
				@include transition(all, .3s, ease);
			}
		}

		&--draw {
			&:before {
				width: 0px;
				transition: none;
			}
		}
	}

	.current-portfolio-slide-number {
		position: absolute;
		bottom: 0;
		right: 0;
		height: 70px;
		width: 70px;
		line-height: 70px;
		text-align: center;
		box-shadow: inset 0 0 0 1px $body-colour;
		@include font-size(30px);
		@include rounded(50%);
	}

	.current-portfolio-slide-number-bg {
		position: absolute;
	    z-index: 5;
	    top: 0;
	    left: 0;
	    width: 68px;
	    height: 68px;
	    margin: 1px;
	    border-radius: 100%;
	    background-color: $light-colour;
		opacity: 1;
		@include transition(all, .3s, ease);
	}

	.current-portfolio-slide-number-text {
		opacity: 1;
		@include transition(all, .3s, ease);
	}

	.current-portfolio-slide-number-text-hide {
		opacity: 0;
	}

	.slider__arrows {
	    top: 0;
		left: 0;
		right: initial;
	    bottom: initial;
	}
}

.portfolio-slider .portfolio-slider__item.slick-active .portfolio-slider__content, .portfolio-slider .portfolio-slider__item.slick-active .portfolio__img-top-right {
	opacity: 1;
	transform: translateX(0);
}

.portfolio-slider .portfolio-slider__item.slick-active .portfolio-slider__img {
	opacity: 1;
	transform: translateX(0);

	@include mobilefirst(em($bp-xxlg)) {
		transform: translateY(0);
	}
}

.portfolio-slider .portfolio-slider__item.slick-active .current-portfolio-slide-number {
	opacity: 1;
}

.current-portfolio-slide-number.draw-active {
	box-shadow: inset 0 0 0 1px $light-colour;
}

.draw-active {

	.current-portfolio-slide-number-text {
		opacity: 0;
	}

	.draw-circle {
		position: absolute;
	    width: 100%;
	    height: 100%;
	    clip: rect(0px, 70px, 70px, 35px);
	    border-radius: 100%;
	    background-color: $light-colour;

		&--fill {
			position: absolute;
		    width: 100%;
		    height: 100%;
		    border-radius: 100%;
		    clip: rect(0px, 35px, 70px, 0px);
			background-color: $body-colour;
		}

		&--left {
			.draw-circle--fill {
				z-index: 1;
			    -webkit-animation: left 0.6s linear;
			    -moz-animation: left 0.6s linear;
			    animation: left 0.6s linear both;
			}

			@keyframes left {
				0% {
					-webkit-transform: rotate(0deg);
				}
				100% {
					transform: rotate(180deg);
				}
			}
			@-webkit-keyframes left {
				0% {
					-webkit-transform: rotate(0deg);
				}
				100% {
					-webkit-transform: rotate(180deg);
				}
			}
		}

		&--right {
			z-index: 3;
		    -webkit-transform: rotate(180deg);
		    -moz-transform: rotate(180deg);
		    transform: rotate(180deg);

			.draw-circle--fill {
				z-index: 3;
				-webkit-animation: right 0.6s linear;
			    -moz-animation: right 0.6s linear;
			    animation: right 0.6s linear both;
			    -webkit-animation-delay: 0.6s;
			    -moz-animation-delay: 0.6s;
			    animation-delay: 0.6s;
			}

			@keyframes right {
				0% {
					-webkit-transform: rotate(0deg);
				}
				100% {
					transform: rotate(180deg);
				}
			}
			@-webkit-keyframes right {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(180deg);
				}
			}
		}
	}
}
