/*------------------------------------*\
    #PRINT
\*------------------------------------*/

@media print{
	header,nav,footer{
		display: none;
	}
	img{
		max-width: 500px;
	}
	body{
		font: 12pt Georgia, "Times New Roman", Times, serif;
		line-height: 1.3em;
	}
}