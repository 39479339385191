/*------------------------------------*\
    #CREDITS
\*------------------------------------*/

/**
 * Author   --  Fishtank Creative
 * Project  --  Chicopee Solutions
 * Credit   --  Fishtank Creative
*/

/**
  * CONTENTS
  *
  * SETTINGS
  * Helpers...........Mixins and variables
  * Base..............Base SASS files - resets, layout, print styles, fonts etc
  * Components........Individual elements such as navigations, footer, header, buttons
  * Templates.........Template files i.e. Home template, inner, contact
*/





/**
  * #Helpers
*/

@import "helpers/mixins";
@import "helpers/functions";
@import "helpers/variables";






/**
  * #BASE
*/

@import "base/reset";
@import "base/base";
@import "base/fonts";
@import "base/grid";
@import "base/links";
@import "base/type";
@import "base/layout";
@import "base/print";





/**
  * #Components
*/


@import "components/buttons";
@import "components/lists";
@import "components/navigation";
@import "components/forms";
@import "components/tables";
@import "components/sliders";
@import "components/slick";
