/*------------------------------------*\
    #BASE
\*------------------------------------*/


/**
  * International box-sizing Awareness Day - Feb 1st
  * In honor of, you guessed it, the most humble, yet awesome CSS property: box-sizing.
  * Introduced to us by Paul Irish - http://bit.ly/1bfMrhO
*/

*, *:before, *:after {
    @include box-sizing(border-box);
}

.js #flash { display: none; }

*:default {
	color: $body-colour;
}

*:focus {
	outline: none;
}

body {
    font-size: 100%;
	position: relative;

    // Font smoothing
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	margin: 48px 0 0 0;

	@include mobilefirst(em($bp-sm)) {
		margin: 20px;
	}
}


img {
    border: none;
}

a {
    @include transition (all, 0.19s, linear);
	color: $body-colour;

	&:hover {
		color: lighten($body-colour, 30%);
	}
}

img,
video,
audio,
iframe,
object {
    max-width:100%;
}

button {
    padding: 0;
    margin: 0;
}

ol,
ul {
    margin-bottom: 35px;
}

li {
    @include font-size($small);
    margin-bottom: 16px;
}

sub, sup {
    font-size: 60%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -9px;
}

button {
    outline: none;
}

object {
    display: block;
}

strong {
    font-weight: bold;
}


/**
  * So to avoid duplicate CSS declarations we have decided to add some single classes
  * Its all for the BEM methodology
*/


.primary   {
    color: $primary-colour;
}

.secondary {
    color: $secondary-colour;
}

.tertiary  {
    color: $tertiary-colour;
}

.light {
    color: $light-colour;

	&--hover:hover {
		color: $light-colour;
	}
}

.black {
	color: $body-colour;
}

.foreground {
    background: $foreground-colour;
}

.background {
    background: $light-colour;

    &--primary {
        background: $primary-colour;
    }
	&--primary-light {
		background-color: $primary-light;
	}
	&--secondary {
        background: $secondary-colour;
    }
	&--secondary-light {
        background: $secondary-light;
    }
	&--grey {
        background: $grey-colour;
    }
}

.fixed-primary {
	.fixed__item, .side-navigation, .footer {
		background-color: $primary-light;
	}
}

.fixed-secondary {
	.fixed__item, .side-navigation, .footer {
		background-color: $secondary-light;
	}
}

.fixed-grey {
	.fixed__item, .side-navigation, .footer {
		background-color: $grey-colour;
	}
}

.align-center { text-align: center; }
.align-left   { text-align: left;   }
.align-right  { text-align: right;  }

.align-top { vertical-align: top; }

.block { display: block; }
.inline { display: inline-block; }

.left  { float: left;  }
.right { float: right; }
.flex  { display: flex; }

.relative { position: relative; }

.auto { margin: auto; }

.hide { display: none; }

.hidden { visibility: hidden; }

.overflow-hidden { overflow: hidden; }

.center {
    @include centre;
}

.center-left {
	@include horizontal-align;
}

.center-top {
    @include vertical-align;
}

.clearfix {
	@include clearfix;
}

.grid-style-helper {
    background: lightblue;
    min-height: 50px;
    margin-bottom: 10px;
}

.top {
	margin-top: 45px;

	&--xsm {
		margin-top: 10px;
	}

	&--sm {
		margin-top: 20px;
	}

	&--md {
		margin-top: 50px
	}

	&--lg {
		margin-top: 90px;
	}

	&--xlg {
		margin-top: 120px;
	}
}

.bottom {
	margin-bottom: 45px;

	&--xsm {
		margin-bottom: 10px;
	}

	&--md {
		margin-bottom: 50px;
	}

	&--lg {
		margin-bottom: 90px;
	}

	&--xxlg {
		margin-bottom: 180px;
	}
}
