/*------------------------------------*\
    #GRID
\*------------------------------------*/

// Set the number of columns you want to use on your layout.
$grid-columns: 12 !default;

// Set the gutter between columns.
$gutter-width: 2.5rem !default;

// Set a margin for the container sides.
$outer-margin: 2rem !default;

// Create or remove breakpoints for your project

// !! IMPORTANT !!
// DUPLICATE THESE VALUES WITHIN VARIABLES FOR bp-{value}
// NEED TO WORK OUT A WAY TO GET THIS WORKING AS A MIXIN
$breakpoints:
  	sm 480px,
  	md 780px,
  	lg 900px,
  	xlg 1060px,
	xxlg 1280px !default;

$flexboxgrid-max-width: 1200px !default;

$gutter-compensation: $gutter-width * .5 * -1;
$half-gutter-width: $gutter-width * .5;

.container {
    max-width: 1370px;
    margin: 0 auto;
    position: relative;
    padding: 0;
    @include clearfix;

	&--inner {
		max-width: 1140px;
	}

	&--inner-sm {
		max-width: 820px;
	}

	&--full {
		padding: 0 90px;
		margin: 0 auto;
	    position: relative;
	    @include clearfix;
	}
}

.row {
  	box-sizing: border-box;
  	@include flexbox();
  	@include flex(0, 1, auto);
  	@include flex-direction(row);
  	@include flex-wrap(wrap);
  	padding: 0 20px;

  	@include mobilefirst(em($bp-md)) {
	  	padding: 0;
  	}
}

.row--full {
	margin-right: 0;
	margin-left: 0;
	padding: 0;
}

.row.reverse {
  	@include flex-direction(row-reverse);
}

.col.reverse {
  	@include flex-direction(column-reverse);
}

@mixin flexboxgrid-sass-col-common {
  	box-sizing: border-box;

  	// split @include flex(0, 0, auto) into individual props
  	@include flex-grow(0);
  	@include flex-shrink(0);

  	// we leave @include flex-basis(auto) out of common because
  	// in some spots we need it and some we dont
  	// more why here: https://github.com/kristoferjoseph/flexboxgrid/issues/126

  	padding-right: $half-gutter-width;
  	padding-left: $half-gutter-width;
}

$name: xs;
.col-#{$name} {
  	@include flexboxgrid-sass-col-common;
  	@include flex-basis(auto);
}

@for $i from 1 through $grid-columns {
  	.col-#{$name}-#{$i} {
    	@include flexboxgrid-sass-col-common;
    	@include flex-basis(100% / $grid-columns * $i);
    	max-width: 100% / $grid-columns * $i;
  	}
}

@for $i from 0 through $grid-columns {
  	.col-#{$name}-offset-#{$i} {
    	@include flexboxgrid-sass-col-common;
    	@if $i == 0 {
      		margin-left: 0;
    	} @else {
      		margin-left: 100% / $grid-columns * $i;
    	}
  	}
}

.col-#{$name} {
  	@include flex-grow(1);
  	@include flex-basis(0);
 	max-width: 100%;
}

.start-#{$name} {
  	@include justify-content(flex-start);
  	text-align: start;
}

.center-#{$name} {
  	@include justify-content(center);
  	text-align: center;
}

.end-#{$name} {
  	@include justify-content(flex-end);
  	text-align: end;
}

.top-#{$name} {
  	@include align-items(flex-start);
}

.middle-#{$name} {
  	@include align-items(center);
}

.bottom-#{$name} {
  	@include align-items(flex-end);
}

.around-#{$name} {
  	@include justify-content(space-around);
}

.between-#{$name} {
  	@include justify-content(space-between);
}

.first-#{$name} {
  	order: -1;
}

.last-#{$name} {
  	order: 1;
}

.col-mob-space {
	margin-bottom: 20px;

	@include mobilefirst(em($bp-sm)) {
		margin-bottom: 0;
	}
}

.full-mob {
	width: 100%;
}

@each $breakpoint in $breakpoints {
   $name: nth($breakpoint, 1);
   $size: nth($breakpoint, 2);

   @media only screen and (min-width: $size) {
    .col-#{$name} {
      @include flexboxgrid-sass-col-common;
      @include flex-basis(auto);
    }
    @for $i from 1 through $grid-columns {
      .col-#{$name}-#{$i} {
        @include flexboxgrid-sass-col-common;
        @include flex-basis(100% / $grid-columns * $i);
        max-width: 100% / $grid-columns * $i;
      }
    }
    @for $i from 0 through $grid-columns {
      .col-#{$name}-offset-#{$i} {
        @include flexboxgrid-sass-col-common;
        @if $i == 0 {
          margin-left: 0;
        } @else {
          margin-left: 100% / $grid-columns * $i;
        }
      }
    }
    .col-#{$name} {
      @include flex-grow(1);
      @include flex-basis(0);
      max-width: 100%;
    }
    .start-#{$name} {
      @include justify-content(flex-start);
      text-align: start;
    }

    .center-#{$name} {
      @include justify-content(center);
      text-align: center;
    }

    .end-#{$name} {
      @include justify-content(flex-end);
      text-align: end;
    }

    .top-#{$name} {
      @include align-items(flex-start);
    }

    .middle-#{$name} {
      @include align-items(center);
    }

    .bottom-#{$name} {
      @include align-items(flex-end);
    }

    .around-#{$name} {
      @include justify-content(space-around);
    }

    .between-#{$name} {
      @include justify-content(space-between);
    }

    .first-#{$name} {
      order: -1;
    }
	.full-mob {
		width: inherit;
	}

    .last-#{$name} {
      order: 1;
      }
   }
}


.nm {
	margin-left: 0;
	margin-right: 0;
}

.np {
	padding-left: 0;
	padding-right: 0;
}

.col-sm-12 {
	width: 100%;

	@include mobilefirst(em($bp-sm)) {
		width: inherit;
	}
}
