/*------------------------------------*\
    #TYPE
\*------------------------------------*/

p {
    @include font-size($base-font-size);
    @include line-height(23px);
    margin-bottom: 15px;
}

.caps { text-transform: uppercase; }


/*------------------------------------*\
    #FONT-STYLES
\*------------------------------------*/

/**
  * Unique Font-Family Names, Matching Weights and Styles
  * To reduce duplicate code we want to separate font styles into their own classes
  * Based on an article here - http://bit.ly/1OUqwGs
*/

.averta-reg {
	font-family: $averta-reg;
    font-weight: 400;
    font-style: normal;
}

.averta-bold {
	font-family: $averta-bold;
    font-weight: 600;
    font-style: normal;
}

.pfd-reg {
	font-family: $pfd-reg;
    font-weight: 400;
    font-style: normal;
}

.pfd-light {
	font-family: $pfd-light;
    font-weight: 300;
    font-style: normal;
}

.pfd-thin {
	font-family: $pfd-thin;
    font-weight: 300;
    font-style: normal;
}


/*------------------------------------*\
    #FONT-SIZING
\*------------------------------------*/

/**
  * Assigning a class for each heading style
  * We now have those styles attached to a very flexible selector that can be moved anywhere
  * Instead of using the .hN notation (i.e .h1) use abstrat classes of the first six letters of
  * Greek alphabet. More information here - http://bit.ly/1P2tXJz
  * Read from an article on CSS Wizary here  - http://bit.ly/1kLpGPM
*/

.alpha {
	@include font-size($sm-xx-large);

	@include mobilefirst(em($bp-md)) {
		@include font-size(58px);
	}

	@include mobilefirst(em($bp-xxlg)) {
		@include font-size($xx-large);
	}
}

.beta {
	@include font-size($sm-x-large);

	@include mobilefirst(em($bp-md)) {
		@include font-size(44px);
	}
	@include mobilefirst(em($bp-xxlg)) {
		@include font-size($x-large);
	}

	&--leading {
		@include line-height(45px);

		@include mobilefirst(em($bp-md)) {
			@include line-height(70px);
		}
	}

	&--leading-lg {
		@include line-height(45px);

		@include mobilefirst(em($bp-md)) {
			@include line-height(107px);
		}
	}
}

.gamma {
	@include font-size($sm-large);

	@include mobilefirst(em($bp-md)) {
		@include font-size($large);
	}

	&--leading {
		@include line-height(38px);

		@include mobilefirst(em($bp-md)) {
			@include line-height(49px);
		}
	}
}

.delta {
	@include font-size($sm-medium);

	@include mobilefirst(em($bp-md)) {
		@include font-size($medium);
	}

	&--leading {
		@include line-height(28px);

		@include mobilefirst(em($bp-md)) {
			@include line-height(30px);
		}
	}
}

.epsilon {
	@include font-size($sm-small);

	@include mobilefirst(em($bp-md)) {
		@include font-size($small);
	}
}

.zeta {
	@include font-size($x-small);

	&--leading {
		@include line-height(17px);

		@include mobilefirst(em($bp-md)) {
			@include line-height(18px);
		}
	}
}

.body {
	@include font-size($x-small);

	@include mobilefirst(em($bp-sm)) {
		@include font-size($base-font-size);
	}
}

// .tracking {
// 	&--20 {
// 		letter-spacing: tracking(20);
// 	}
// 	&--100 {
// 		letter-spacing: tracking(100);
// 	}
// }

/**
  * Abstract classes, this time borrowing SI prefixes.
  * These classes are the ones above h1 and are the seldom used ones that make stuff massive!
  * Please use with !!Caution
*/
